$(document).ready(function() {
    $(window).scroll(function(){
        if ($(this).scrollTop() > 10) {
            $('.navbar').addClass('fixed-top');
        } else {
            $('.navbar').removeClass('fixed-top');
        }
    });

    $('.btn-scroll').click(function() {
        var $link = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(""+$link+"").offset().top - 200
        }, 1000);
        if ($('.navbar-collapse').hasClass('in')){
            $('.navbar-collapse').removeClass('in');
            $('.navbar-collapse').attr('aria-expanded', false);
            $('.navbar-collapse').css({ 'height': '1px'});
        }
        return false;
    });

    $('.contact-submit').click(function(){
        var form = $(this).closest('form'),
            email = form.find('input[name=contact_email]'),
            message = form.find('textarea[name=contact_message]'),
            $errors = 0;
            form.find('input').removeClass('is-invalid');
            form.find('.invalid-feedback').hide();
        if (email.val()==='') {
            email.addClass('is-invalid');
            email.parents('.form-group').find('.invalid-feedback').show();
            $errors ++;
        }else{
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            var address = email.val();
            if(reg.test(address) === false) {
                email.addClass('is-invalid');
                email.parents('.form-group').find('.invalid-feedback').show();
                $errors ++;
            }
        }
        if (message.val()==='') {
            message.addClass('is-invalid');
            message.parents('.form-group').find('.invalid-feedback').show();
            $errors ++;
        }
        if ($errors ===0){
            var actions = form.attr('action');
            $(this).prop('disabled',true);
            $(this).find('.fa-spinner').show();

            $.ajax({
                type:"POST",
                url:actions,
                data:form.serialize(),
                dataType: 'json',
                success: function(data){
                    $('.contact-submit').prop('disabled',false);
                    $('.contact-submit').find('.fa-spinner').hide();
                    $('#contacts .alert-success').show();
                },
                error: function(data){
                    $('.contact-submit').prop('disabled',false);
                    $('.contact-submit').find('.fa-spinner').hide();
                    $('#contacts .alert-danger').show();
                }
            });

        }
        return false;
    });

});
